import React from "react";
import 'animate.css';
import './styles/style.scss';
import { NavLink, useParams } from "react-router-dom";
import { useFetchPostQuery } from "./services/post-api";
import { Breadcrumb, Row, Col } from "antd";

export default function PostDetailPage() {
    const { slug } = useParams();
    const post = useFetchPostQuery(slug);

    return (
        <div className="post-page animate__animated animate__fadeIn">
            <div className="container">
                <Row>
                    <Col>
                        <div className="post-detail">
                            <div className="content">
                                <div className="post-breadcrumb">
                                    <Breadcrumb items={[{ title: <NavLink to={'/'}>Accueil</NavLink> }, { title: 'Actualités' }]} />
                                </div>
                                <h1>{post?.data?.title.rendered}</h1>
                            </div>
                            <div className="post-img" style={{ backgroundImage: `url(${post?.data?.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} />
                            <div className="content">
                                <div className="post-detail-content">
                                    <div dangerouslySetInnerHTML={{ __html: post?.data?.content.rendered }} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
