import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from "../../../auth/services/auth-slice";
import { loadTokenState } from "../../../../utils/local-storage";
import { Upload, Avatar } from 'antd';
import { API_ROOT } from "../../../../utils/global-vars";
import { useAccountUpdateUserMutation } from "../../services/account-api";
import { useAuthProfileMutation } from "../../../auth/services/auth-api";
import './styles/style.scss';


function AccountHeader() {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [filename, setFilename] = useState();
    const [authProfile] = useAuthProfileMutation();
    const [accountUpdateUser] = useAccountUpdateUserMutation();


    useEffect(() => {
        dispatch(setUser(loadTokenState()?.user))
    }, []);

    const props = {
        name: "file",
        multiple: false,
        action: `${API_ROOT}/uploads/single`,
        onChange(info) {
            setFilename(info.file.response?.filename);
            handleUpdate(info.file.response?.filename);
        },
        onDrop(e) {
            console.log("Dropped files", e.dataTransfer.files);
        },
    };


    const handleUpdate = (value) => {
        const data = { avatar: value, id: user.id }
        accountUpdateUser(data)
            .unwrap()
            .then((res) => {
                console.log("res", res)
                handleFetchUser()
            })
            .catch((error) => {
            });
    };

    const handleFetchUser = async () => {
        authProfile({ "access_token": loadTokenState()?.accessToken })
            .unwrap()
            .then((res) => {
                dispatch(setUser(res))
            })
            .catch((error) => {
            });
    };

    return (
        <div className="animate__animated animate__fadeIn account-header">
            <div className="container">
                <div className="profile">
                    <div>
                        <Upload {...props}>
                            {user?.avatar ?
                                <Avatar size={100} src={<img src={`${API_ROOT}/uploads/${user?.avatar}`} alt="logo" />} /> :
                                <img src="/img/profile.png" alt="logo" className="profile-img" />}
                        </Upload>
                        <h6>{user?.lastname} {user?.firstname}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccountHeader;
