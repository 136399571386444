import React from "react";
import { Link } from 'react-router-dom';
import { API_ROOT, calculateAge, textToSlug } from "../../utils/global-vars";
import { Col, Row } from "antd";
import './styles/style.scss';


export default function ProductItem({ item }) {

    // console.log("item:", item.image.le);

    return (
        <div className="product-item">
            <Link to={`/bergeries/ladoum/${textToSlug(item.libelle)}`} state={item}>
                <Row gutter={24} >
                    <Col lg={12} xs={24}>
                        <div className="blc-left">
                            <div className="blc-img">
                                {
                                    Array.isArray(JSON.parse(item.image)) ? (
                                        <img src={`${API_ROOT}/uploads/${JSON.parse(item.image)[0]}`} alt="Image principale" />
                                    ) : (
                                        <img src={`${API_ROOT}/uploads/${JSON.parse(item.image)}`} alt="Image principale" />
                                    )
                                }

                                {/* <img src={`${API_ROOT}/uploads/${JSON.parse(item.image)[0]}`} /> */}
                                <img src={`${API_ROOT}/uploads/${JSON.parse(item.image)[0]}`} />
                            </div>
                        </div>
                    </Col>
                    <Col lg={12} xs={24}>
                        <div className="blc-right">
                            <div className="title">{item.libelle}</div>
                            <div className="blc-content">
                                <div className="blc-content-wrap">
                                    <table>
                                        <tr>
                                            <td><div className="blc-item">Âge: <span>{calculateAge(item.dob ?? "0")}</span> </div></td>
                                            <td><div className="blc-item">Père: <span></span>{item.parent_male}</div></td>
                                        </tr>

                                        <tr>
                                            <td><div className="blc-item">Sexe: <span>{item.sexe}</span> </div></td>
                                            <td><div className="blc-item">Poids: <span>{item.poids}Kg</span> </div></td>
                                        </tr>

                                        <tr>
                                            <td><div className="blc-item">Provenace du père:  </div></td>
                                            <td><div className="blc-item"> <span>{item.provenance_pere}</span> </div></td>
                                        </tr>

                                        <tr>
                                            <td><div className="blc-item">Provenace de la mère:  </div></td>
                                            <td><div className="blc-item"> <span>{item.provenance_mere}</span> </div></td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className="proprietaire">
                                                    <div className="blc-item">Propriétaire: <span>{item.user.firstname} {item.user.lastname}</span></div>
                                                    <div className="blc-item">Bergerie: <span>{item.bergerie?.libelle}</span></div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Link>
        </div>
    );
}
