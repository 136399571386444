import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Spin, Select, notification, Upload, DatePicker } from "antd";
import { useSelector } from "react-redux";
import { useFetchParentByUseIdQuery } from "../services/parent-api";
import { useUpdateLadoumMutation } from "../services/ladoums-api";
import { useFetchBergeriesByUseIdQuery } from "../services/bergeries-api";
import { InboxOutlined } from "@ant-design/icons";
import 'animate.css';
import { API_ROOT } from "../../../utils/global-vars";
import { useLocation } from "react-router-dom";
const { Option } = Select;
const { TextArea } = Input;

export default function LadoumEditPage() {
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [updateLadoum] = useUpdateLadoumMutation();
    const { user } = useSelector((state) => state.auth);
    const parents = useFetchParentByUseIdQuery(user.id);
    const bergeries = useFetchBergeriesByUseIdQuery(user.id);
    const [filename, setFilename] = useState();
    const [filenames, setFilenames] = useState([]);

    const [dob, setDob] = useState();
    const [form] = Form.useForm();
    const { Dragger } = Upload;

    const onFinish = (values) => {
        const data = { ...values, id: state.id, dob: dob ?? state.dob, image: filenames ? JSON.stringify(filenames) : state.image }
        setIsLoading(true);
        updateLadoum(data)
            .unwrap()
            .then(() => {
                setIsLoading(false);
                notification.success({ message: 'mise à jour effectuée' })
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({ message: error.message })
            });
    };

    useEffect(() => {
        form.setFieldsValue({
            ...state,
            bergerie: state.bergerie?.id
        });
    }, [state]);

    const props = {
        name: 'file',
        multiple: true,
        action: `${API_ROOT}/uploads/single`,
        onChange(info) {
            setFilenames((prevFilenames) =>
                [...prevFilenames, info.file.response?.filename].filter(
                    (name) => name !== null && name !== undefined
                )
            );
        },
        onDrop(e) { },
    };


    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Editer un Ladoum </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <div className="form-wrapper">
                        <Form name="basic" layout="vertical" autoComplete="off" onFinish={onFinish} form={form}>
                            <Row gutter={24}>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="Nom" name="libelle" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col lg={12} xs={24}>
                                    <Form.Item label="sexe" name="sexe" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Select allowClear>
                                            <Option value="male">Male</Option>
                                            <Option value="female">Femelle</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Parent male" name="parent_male">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Parent femelle" name="parent_femelle">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Provenance du père" name="provenance_pere">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Provenance de la mère" name="provenance_mere">
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Poids" name="poids" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Taille" name="taille" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Bergeries" name="bergerie" rules={[{ required: true, message: "Champs requis!" }]}>
                                        <Select allowClear loading={bergeries.isLoading}>
                                            {bergeries?.data
                                                ?.map((item, index) => (
                                                    <Option value={item.id} key={index}>
                                                        {item.libelle}
                                                    </Option>
                                                ))
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={12} xs={24}>
                                    <Form.Item label="Naissance">
                                        <DatePicker onChange={(date, dateString) => setDob(dateString)} placeholder={state.dob} format="DD/MM/YYYY" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Form.Item label="Description" name="description">
                                <TextArea rows={4} className="form-control" />
                            </Form.Item>
                            {/* <div style={{ margin: '20px 0px' }}>
                                <Form.Item
                                    label="Image"
                                    name="image"
                                    rules={[{ required: true, message: "Champs requis!" }]}>
                                    <Upload {...props}>
                                        <Button icon={<UploadOutlined />}>
                                            Click to Upload
                                        </Button>
                                    </Upload>
                                </Form.Item>
                            </div> */}
                            <div style={{ margin: '20px 0px' }}>
                                <Form.Item
                                    label="Image"
                                    name="image"
                                    rules={[{ required: true, message: "Champs requis!" }]}>
                                    <Dragger {...props}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Cliquez ou glissez un fichier dans cette zone pour l'envoyer.</p>
                                        <p className="ant-upload-hint">
                                            Supporte l'envoi unique ou en lot.
                                        </p>
                                    </Dragger>
                                </Form.Item>
                            </div>
                            <Form.Item>
                                <Button type="primary" className="btn btn-primary" htmlType="submit">
                                    Enregistrer
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Spin>
            </div>
        </div>
    );
}
