import React, { useEffect, useState } from "react";
import { Col, Row, Image } from 'antd';
import { API_ROOT, calculateAge } from "../../utils/global-vars";
import { useLocation } from "react-router-dom";
import ImageGallery from "react-image-gallery";
import 'animate.css';
import './styles/style.scss';

export default function LadoumsDetailPage() {
    const { state } = useLocation();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_ROOT}/ladoums/${state.id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setData(result);
                setLoading(false);

                console.log("result:", result);

            } catch (err) {
                setLoading(false);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);



    const convertToImageObjects = () => {
        return JSON.parse(state.image)?.map((fileName) => {
            return {
                original: `${API_ROOT}/uploads/${fileName}`,
                thumbnail: `${API_ROOT}/uploads/${fileName}`,
            };
        });
    }

    const images = convertToImageObjects(state?.image);


    return (
        <div className="landoum-detail-page animate__animated animate__fadeIn">
            <div className="section-1">
                <div className='container'>
                    {data &&
                        <Row gutter={24}>
                            <Col lg={16}>
                                <div className="blc-left">
                                    <div className="gallery">
                                        <ImageGallery
                                            showPlayButton={false}
                                            infinite={false}
                                            showNav={false}
                                            items={images} />
                                    </div>

                                    <div className="detail">
                                        <div className="title">Details</div>
                                        <div className="subtitle">{state.description}</div>
                                        <div className="subtitle">Poids: <span>{data.poids} kg</span></div>
                                        <div className="subtitle">Sexe: <span>{data.sexe}</span> </div>
                                        <div className="subtitle">Age: <span>{calculateAge(data.dob ?? "0")} </span> </div>
                                        <div className="subtitle">Père: <span>{data.parent_male} </span> </div>
                                        <div className="subtitle">Provenance du Père: <span>{data.provenance_pere}</span> </div>
                                        <div className="subtitle">Mère: <span>{data.parent_femelle}</span> </div>
                                        <div className="subtitle">Provenance de la Mère: <span>{data.provenance_mere}</span> </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="blc-right">
                                    <div className="libelle">{state.libelle}</div>
                                    <div className="bergerie">
                                        <div className="title">Bergerie</div>
                                        <div className="subtitle">{data.bergerie.libelle}</div>
                                        <div className="subtitle">{data.bergerie.adresse}</div>
                                    </div>

                                    <div className="proprietaire">
                                        <div className="title">Propriétaire</div>
                                        <div className="subtitle">{data?.user?.firstname}  {data?.user?.lastname}</div>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        </div>
    );
}
