import React, { useEffect, useState } from "react";
import { Skeleton } from 'antd';
import 'animate.css';
import './styles/style.scss';
import { API_ROOT } from "../../utils/global-vars";
import Banner from "../../components/Banner/Banner";
import ProductItem from "../../components/ProductItem/productItem";

export default function LadoumsList() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        handleSearch(event.target.value)
    };

    const handleSearch = async (value) => {
        setLoading(true);
        try {
            const response = await fetch(`${API_ROOT}/ladoums/search?libelle=${value}`);
            if (!response.ok) {
                throw new Error("Failed to fetch data");
            }
            const result = await response.json();
            setData(result);
            setLoading(false);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await fetch(`${API_ROOT}/ladoums`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setData(result.data);
                setLoading(false);
            } catch (err) {
                setLoading(false);
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);


    return (
        <div className="landoum-page animate__animated animate__fadeIn">
            <Banner title="Nos Moutons" />
            <div className="section-1">
                <div className='container'>
                    <div className="search-form">
                        <div className="">
                            <input
                                type="text"
                                placeholder="Rechercher..."
                                value={searchTerm}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    {
                        loading ? <Skeleton paragraph={{ rows: 10 }} /> :
                            data.map((item) => (<ProductItem item={item} />))
                    }
                </div>
            </div>
        </div >
    );
}
