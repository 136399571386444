export const API_ROOT = "https://api.universdesladoums.com";
// export const API_ROOT = "http://localhost:3000";


export const textToSlug = (title) => {
    return title
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export const calculateAge = (dateString) => {
    if (!dateString) {
        return "Date non valide";
    }

    const birthDate = new Date(dateString);
    const today = new Date();

    // Calculer la différence en mois
    const totalMonths =
        (today.getFullYear() - birthDate.getFullYear()) * 12 +
        (today.getMonth() - birthDate.getMonth());

    if (totalMonths < 0) {
        return "Date future non valide";
    }

    if (totalMonths < 12) {
        return `${totalMonths} mois`;
    }

    // Calculer les années complètes
    const ageInYears = Math.floor(totalMonths / 12);
    return `${ageInYears} ans`;
};
