import React, { useState } from "react";
import { Avatar, Space, Spin, Table, notification, Popconfirm } from "antd";
import { useSelector } from "react-redux";
import 'animate.css';
import { useDeleteBergeriesMutation, useFetchBergeriesByUseIdQuery } from "../services/bergeries-api";
import { API_ROOT, textToSlug } from "../../../utils/global-vars";
import { NavLink } from "react-router-dom";

export default function BergeriesListPage() {
    const { user } = useSelector((state) => state.auth);
    const { data: products, isLoading } = useFetchBergeriesByUseIdQuery(user.id);
    const [loading, setLoading] = useState(false);
    const [deleteBergeries] = useDeleteBergeriesMutation();
    const [bergerieId, setBergerieId] = useState();

    const confirm = (e) => {
        handleDelete()
    };

    const cancel = () => { };

    const columns = [
        {
            title: "Nom de la bergerie",
            key: "libelle",
            render: (record) => (
                <div className="btn-view">
                    <Space>
                        <Avatar src={`${API_ROOT}/uploads/${record?.image}`} alt="avatar" size={54} />
                        {record.libelle}
                    </Space>
                </div>
            ),
        },
        {
            title: "Adresse",
            dataIndex: "adresse",
            key: "adresse",
            render: (text) => <span>{text}</span>,
        },

        {
            title: "Action",
            render: (record) => (
                <div className="btn-view">
                    <Space>
                        <NavLink to={`/account/bergeries/edit/${textToSlug(record.libelle)}`} state={record}>
                            <span className="link-edit">Editer</span>
                        </NavLink>
                        <Popconfirm
                            title="Suppression"
                            description="Êtes-vous sûre de vouloir supprimer?"
                            onConfirm={confirm}
                            onCancel={cancel}
                            okText="Oui"
                            cancelText="Non">
                            <span className="link-delete" onClick={() => setBergerieId(record.id)}>Supprimer</span>
                        </Popconfirm>
                    </Space>
                </div>
            ),
        },
    ];

    const handleDelete = () => {
        setLoading(true);
        deleteBergeries(bergerieId)
            .unwrap()
            .then(() => {
                setLoading(false);
                notification.success({ message: 'Suppression effectuée avec succès' })
            })
            .catch((error) => {
                setLoading(false);
                notification.error({ message: error.message })
            });
    }


    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Liste des bergeries </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading || loading}>
                    <Table columns={columns} dataSource={products} />
                </Spin>
            </div>
        </div>
    );
}
