import React from "react";
import { Col, Row, Form, Button, Input } from 'antd';
import 'animate.css';
import './styles/style.scss';
import Banner from "../../components/Banner/Banner";
const { TextArea } = Input;


export default function ContactPage() {
    return (
        <div className="contact-page animate__animated animate__fadeIn">
            <Banner title="Nous contacter" subtitle="Nous serions ravis de vous entendre. Remplissez le formulaire ci-dessous pour nous contacter." page="Contact" />
            <div className="section-1">
                <div className="container">
                    <Row gutter={24}>
                        <Col lg={12} xs={24}>
                            <div className="blc-left">
                                <div className="blc-left-item">
                                    <div className="title">Adresse</div>
                                    <div className="subTitle">123, rue taglou Ouakam</div>
                                </div>

                                <div className="blc-left-item">
                                    <div className="title">Email</div>
                                    <div className="subTitle">exemple@exemple.com</div>
                                </div>

                                <div className="blc-left-item">
                                    <div className="title">TÉLÉPHONE</div>
                                    <div className="subTitle">+221 78 755 50 50</div>
                                </div>

                                <div className="blc-left-item">
                                    <div className="title">Adresse</div>
                                    <div className="subTitle">123, rue taglou Ouakam</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={12} xs={24}>
                            <div className="blc-right">
                                <Form
                                    name="basic"
                                    initialValues={{ phone: "", password: "" }}
                                    layout="vertical"
                                    autoComplete="off"
                                >
                                    <Row gutter={24}>
                                        <Col lg={12} xs={24} >
                                            <Form.Item
                                                label="Nom"
                                                name="phone"
                                                rules={[{ required: true, message: "Champs requis!" }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>

                                        <Col lg={12} xs={24}>
                                            <Form.Item
                                                label="Prénom"
                                                name="phone"
                                                rules={[{ required: true, message: "Champs requis!" }]} >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>


                                    <Form.Item
                                        label="Email"
                                        name=""
                                        rules={[{ required: true, message: "Champs requis!" }]}
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item>
                                        <TextArea rows={6} />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" className="btn btn-lg btn-primary" htmlType="submit">
                                            Envoyer un message
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </div >
    );
}
