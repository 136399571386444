import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Spin, Alert, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useAuthProfileMutation } from "../auth/services/auth-api";
import { setUser } from "../auth/services/auth-slice";
import { useAccountUpdateUserMutation } from "./services/account-api";
import 'animate.css';
import './styles/style.scss';
import { loadTokenState } from "../../utils/local-storage";

const { TextArea } = Input;

export default function AccountPage() {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState();
    const [authProfile] = useAuthProfileMutation();
    const [accountUpdateUser] = useAccountUpdateUserMutation();
    const { user } = useSelector((state) => state.auth);
    const [form] = Form.useForm();

    const handleFetchUser = async () => {
        setIsLoading(true);
        authProfile({ "access_token": loadTokenState()?.accessToken })
            .unwrap()
            .then((res) => {
                console.log()
                dispatch(setUser(res))
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                setError(error.data.message);
            });
    };

    const onFinish = (values) => {
        const data = { ...values, id: user.id }
        setIsLoading(true);
        accountUpdateUser(data)
            .unwrap()
            .then(() => {
                handleFetchUser()
                notification.success({ message: 'mise à jour effectuée' })
            })
            .catch((error) => {
                setIsLoading(false);
                notification.error({ message: error.message })
            });
    };



    useEffect(() => {
        form.setFieldsValue(user);
    }, [user]);

    return (
        <div className="animate__animated animate__fadeIn account-profile">
            <div className="title">
                <h3>Profile </h3>
            </div>

            <div className="content">
                <Spin tip="Chargement..." spinning={isLoading}>
                    <Form name="basic" layout="vertical" autoComplete="off" form={form}
                        initialValues={{ remember: true }} onFinish={onFinish}>
                        <Row gutter={24}>
                            <Col lg={12} xs={24}>
                                <Form.Item label="Nom" name="firstname">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col lg={12} xs={24}>
                                <Form.Item label="Prénom" name="lastname">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col lg={12} xs={24}>
                                <Form.Item label="Téléphone" name="phone">
                                    <Input />
                                </Form.Item>
                            </Col>

                            <Col lg={12} xs={24}>
                                <Form.Item label="Email" name="email">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col lg={24} xs={24}>
                                <Form.Item label="Presentation" name="description">
                                    <TextArea rows={4} className="form-control" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item>
                            <Button type="primary" className="btn btn-primary" htmlType="submit">
                                Sauvegarder
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
                {error && <Alert message={error} type="error" />}
            </div>
        </div>
    );
}
