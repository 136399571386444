import React, { useEffect, useState } from "react";
import { Carousel } from 'antd';
import 'animate.css';
import './styles/style.scss';
import { API_ROOT } from "../../utils/global-vars";
import { NavLink } from "react-router-dom";
import ProductItem from "../../components/ProductItem/productItem";

export default function HomePage() {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_ROOT}/ladoums?per_page=3&page=1`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setData(result.data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    const onChange = (currentSlide) => {
        console.log(currentSlide);
    };

    return (
        <div className="home-page animate__animated animate__fadeIn">
            <div className='container-head'>
                <div className="section-1">
                    <h3>Découvrez nos moutons de race pure, élevés avec passion et savoir-faire</h3>
                    <div className="section-content">
                        <Carousel arrows infinite={true} afterChange={onChange}>
                            <div>
                                <img src="img/slide-1.png" className="carousel-img" />
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
            <div className="section-2">
                <div className='container'>
                    <div className='section-product'>
                        <h1>Univers des Ladoums, <span>c’est quoi ?</span></h1>
                        <h3><span>L'UNIVERS DES LADOUMS !</span> Découvrez la beauté des Ladoums, une race de moutons prisée au Sénégal. Suivez notre passion pour l'élevage, partagez des anecdotes sur nos bergeries et explorez l'héritage de chaque Ladoum.</h3>
                        <div className="nos-moutons">Nos Moutons</div>
                        {
                            data.map((item) => (<ProductItem item={item} />))
                        }
                    </div>
                    {data.length > 0 && <div className="voir-plus"><NavLink to={"/nos-moutons"}>Voir plus...</NavLink> </div>}
                </div>
            </div>
        </div>
    );
}
