import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton } from 'antd';
import 'animate.css';
import './styles/style.scss';
import { API_ROOT, textToSlug } from "../../utils/global-vars";
import { Link, useLocation } from "react-router-dom";
import ProductItem from "../../components/ProductItem/productItem";

export default function BergeriesDetailPage() {
    const { state } = useLocation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${API_ROOT}/ladoums/bergerie/${state.id}`);
                if (!response.ok) {
                    throw new Error("Failed to fetch data");
                }
                const result = await response.json();
                setData(result);
                setLoading(false);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, []);



    return (
        <div className="bergerie-detail-page animate__animated animate__fadeIn">
            <div className="section-1">
                <div className='container'>
                    <Row>
                        <Col lg={18} xs={24}>
                            <div className="blc-left">
                                <div className="title">{state.libelle}</div>
                                <div className="desc">{state.description}</div>
                            </div>
                        </Col>

                        <Col lg={6} xs={24}>
                            <div className="blc-right">
                                <div className="img" style={{ backgroundImage: `url(${API_ROOT}/uploads/${state.image})` }} />
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="section-2">
                    <div className="container">
                        {
                            loading ? <Skeleton paragraph={{ rows: 10 }} /> :
                                data.map((item) => (<ProductItem item={item} />))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
